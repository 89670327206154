import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"images",
        "id":"image_id",
        "options":["edit","insert","delete"],
        "nameSingle":"image",
        "nameMultiple":"images",
        "filters":
            [
               
            ]
        ,
        "fields":{
            "field1":{
                "name":"Key",
                "field":"image_key",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Image",
                "field":"image_image",
                "type":"ImageUpload",
                "list":true,
            }
        }
    }
    
}